import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import('../fan-club-members'));
const AsyncSkeletonPage = React.lazy(() => import('../fan-club-members.skeleton'));

const routes: Route = {
  name: id,
  path: '/{lang?}/statistics/fan-club',
  searchParams: ['page', 'search', 'statuses'],
  element: <AsyncPage />,
  skeleton: <AsyncSkeletonPage />,
};

export default routes;
