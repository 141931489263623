import { of } from 'rxjs';
import type { RouteAuthorization } from 'contracts';
import ff from 'services/feature-toggle';

import id from './id';

const authorization: RouteAuthorization = {
  name: id,
  authorization$: of(ff.enabled('enableSimplifiedRegistration')),
};

export default authorization;
